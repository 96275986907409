<template>
  <div>
    <v-dialog :value="show" max-width="500px" @input="close()">
      <v-card>
        <v-card-title>
          <v-layout align-center justify-space-between>
            <span class="headline">{{ formTitle }}</span>

            <v-btn icon dark class="ma-0" @click.native="close">
              <v-icon>close</v-icon> 
            </v-btn>
          </v-layout>
        </v-card-title>

        <v-card-text>
          <div>
            <div class="mb-2 grey--text">
              Please pick the reason why you have deleted this post
              <div class="error--text">
                If you delete the post and then restore it, its comments will
                not be restored
              </div>
            </div>
            <v-select
              v-model="selectedReason"
              :items="reason"
              :error-messages="reasonErrors"
              item-value="id"
              item-text="reason"
              name="reason"
              solo
              box
              label="Select reason"
              return-object
              @change="reasonChange"
            />
          </div>

          <div>
            <div class="mb-2 grey--text">
              Add more info (not necessary)
            </div>
            <v-textarea
              v-model="description"
              :error-messages="descriptionErrors"
              counter="1000"
              solo
              box
              rows="7"
              name="description"
              value=""
              @input="descriptionChange"
            />
          </div>
        </v-card-text>

        <v-card-actions class="px-3 pt-0">
          <v-spacer />
          <v-btn color="error" @click.native="close">
            Cancel
          </v-btn>
          <v-btn color="success" :loading="isLoading" @click.native="save()">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { deletePost } from "../../../api/post"
export default {
  name: "DeletePostModal",
  props: {
    value: Boolean,
    postId: {
      type: [Number, String],
      default: null
    },
    reason: {
      type: [Array, Object],
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
      selectedReason: {},
      formTitle: "Delete post",
      description: null,
      reasonErrors: null,
      descriptionErrors: null
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  watch: {
    show: function(isOpen) {
      if (isOpen) {
        document.querySelector("html").style.overflowY = "hidden"
      } else {
        document.querySelector("html").style.overflowY = "auto"
      }
    }
  },
  methods: {
    reasonChange() {
      this.reasonErrors = null
    },
    descriptionChange() {
      this.descriptionErrors = null
    },
    descriptionValid(val) {
      return val.length <= 1000
    },
    close() {
      this.description = null
      this.selectedReason = {}
      this.reasonErrors = null
      this.descriptionErrors = null
      this.show = false
    },
    async save() {
      this.reasonErrors = null
      this.descriptionErrors = null

      if (!this.selectedReason.id) {
        this.reasonErrors = "The reason field is required"
        return
      }

      if (
        this.selectedReason.id === 10 &&
        (this.description === "" || this.description === null)
      ) {
        this.descriptionErrors = "The description field is required"
        return
      }

      if (this.description && this.description.length) {
        if (!this.descriptionValid(this.description.trim())) {
          this.descriptionErrors = "Max 1000 characters"
          return
        }
      }

      const params = {
        post_id: this.postId,
        reason_id: this.selectedReason.id,
        description: this.description
      }

      this.isLoading = true
      await deletePost(params)
        .then(response => {
          if (response && response.status === 200) {
            this.close()
            this.$emit("deleteCurrentPost", this.postId)
            this.$notificationShow(response.data.message)
          }
        })
        .catch(error => {
          this.reasonErrors = error.response.data.errors.reason
            ? error.response.data.errors.reason[0]
            : null
          this.descriptionErrors = error.response.data.errors.description
            ? error.response.data.errors.description[0]
            : null
        })
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>
