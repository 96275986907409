<template>
  <div>
    <v-card class="pa-3">
      <v-progress-linear
        :active="isLoading"
        :indeterminate="isLoading"
        :height="1"
        class="pa-0 ma-0"
      />
    </v-card>

    <v-card v-if="reportList && userInfo && userInfo.abuser" class="pa-3">
      <v-layout row>
        <v-flex xs12>
          <a
            :href="createUrl(userInfo.slug)"
            class="title text--darken-1 link-hover"
            target="_blank"
          >
            {{ userInfo.post_title }}
          </a>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs3>
          <div class="mb-3">
            <v-avatar
              v-if="userInfo.abuser && userInfo.abuser.image"
              size="65px"
            >
              <img :src="userInfo.abuser.image" alt="">
            </v-avatar>
            <v-avatar v-else size="65px">
              <v-icon class="no-avatar-icon">
                mdi-account
              </v-icon>
            </v-avatar>
          </div>

          <!--            <div-->
          <!--              class="subheading text&#45;&#45;darken-1 ml-4"-->
          <!--              v-text="userInfo.abuser.username"-->
          <!--            ></div>-->
          <div
            class="break subheading text--darken-1 mb-3"
            v-text="userInfo.abuser.username"
          ></div>
          <!--          </v-layout>-->

          <!--          <div class="mt-3 mb-4">-->
          <!--            <span-->
          <!--              v-for="(tag, index) in userInfo.tags"-->
          <!--              :key="index"-->
          <!--              class="font-size-15 bold deep-orange&#45;&#45;text mr-2"-->
          <!--            >-->
          <!--              {{ tag.title }}-->
          <!--            </span>-->
          <!--          </div>-->

          <div class="mb-4">
            <div class="mb-2">
              <v-icon class="blue-grey--text mr-2">
                mode_comment
              </v-icon>
              <span class="bold mr-2">
                {{ userInfo.abuser.comments_count }}
              </span>
              comments
            </div>
            <div class="mb-1">
              <v-icon class="blue-grey--text mr-2">
                photo
              </v-icon>
              <span class="bold mr-2">
                {{ userInfo.abuser.posts_count }}
              </span>
              Posts
            </div>
            <div class="mb-2">
              <v-icon class="blue-grey--text mr-2">
                thumb_up
              </v-icon>
              <span class="bold mr-2">
                {{ userInfo.abuser.user_set_like_down_votes_count }}
              </span>
              upvotes
            </div>
            <div class="mb-2">
              <v-icon class="blue-grey--text mr-2">
                thumb_down
              </v-icon>
              <span class="bold mr-2">
                {{ userInfo.abuser.user_set_like_up_votes_count }}
              </span>
              downvotes
            </div>
          </div>

          <!--          <div class="headline">-->
          <!--            Reports:-->
          <!--            <span class="bold">-->
          <!--              {{ userInfo.reports_count }}-->
          <!--            </span>-->
          <!--          </div>-->
        </v-flex>

        <v-flex xs9>
          <v-data-table
            :items="reportList"
            :loading="isLoading"
            class="elevation-1"
            hide-actions
            hide-headers
          >
            <template slot="items" slot-scope="props">
              <td class="py-2">
                <div>
                  Has been reported for
                  <b class="orange--text">{{ props.item.reason }}</b>
                  by
                  <router-link
                    :to="`/moderation/user_list/user/${props.item.user.id}`"
                    class="blue-grey--text bold link-hover ml-1"
                  >
                    {{ props.item.user.username }}
                  </router-link>
                </div>
                <div
                  v-if="props.item.description"
                  class="my-2"
                  v-text="props.item.description"
                ></div>
              </td>
              <td class="text-xs-right">
                <div
                  class="date"
                  v-text="dateFormat(props.item.created_at)"
                ></div>
              </td>
            </template>
          </v-data-table>

          <div class="text-xs-center mt-4">
            <div class="text-xs-center">
              <v-pagination
                v-model="pagination.page"
                :length="pages"
                @input="refreshPagination"
              />
            </div>
          </div>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs3>
          <v-btn
            color="warning"
            class="ma-0"
            :disabled="postDeleted"
            @click="openDeletePostModal(userInfo.post_id)"
          >
            <span v-if="postDeleted">Deleted</span>
            <span v-else>Delete</span>
          </v-btn>
          <v-btn
            :disabled="isLoading"
            color="error"
            class="ma-0 ml-2"
            @click="openForeverDeleteModal(userInfo.post_id)"
          >
            <span>Delete forever</span>
          </v-btn>
        </v-flex>

        <v-flex xs9 class="text-xs-right">
          <v-btn flat class="blue-grey ma-0" @click="returnBack()">
            <v-icon left dark class="font-size-20">
              arrow_back
            </v-icon>
            Back
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>
    <delete-post-modal
      v-model="deletePostModal"
      :post-id="postId"
      :reason="reason"
      @deleteCurrentPost="deleteCurrentPost"
    />
    <accept-modal
      v-model="showAcceptModal"
      :post-id="postId"
      @accept="deleteForeverPost"
    />
  </div>
</template>

<script>
import DeletePostModal from "../../../../components/Moderation/PostList/DeletePostModal"
import { postReportsRequest } from "../../../../api/post"
import moment from "moment"
import { getReasons } from "../../../../api/reason"
import { BaseUrl } from "../../../../config"
import AcceptModal from "@/components/Modals/AcceptModal"

export default {
  name: "ReportedParsedPost",
  components: {
    DeletePostModal,
    AcceptModal
  },
  data() {
    return {
      postDeleted: false,
      deletePostModal: false,
      reason: [],
      postId: "",
      isLoading: false,
      page: 1,
      prevRoute: null,
      reportList: [],
      total: 0,
      pagination: {
        page: 1,
        rowsPerPage: 5
      },
      userInfo: {},
      showAcceptModal: false,
    }
  },
  computed: {
    pages() {
      if (this.pagination.rowsPerPage == null || this.total == null) return 0

      return Math.ceil(this.total / this.pagination.rowsPerPage)
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  async created() {
    await this.getReasonBlockUser()
    await this.getPostReportsRequest()
  },
  methods: {
    openForeverDeleteModal(id){
      this.postId = id
      this.showAcceptModal = true
    },
    deleteForeverPost(){
      this.$router.go(-1)
      this.$notificationShow(
          `The post is successfully deleted`
      )
    },
    deleteCurrentPost(id) {
      this.postDeleted = id
    },
    openDeletePostModal(id) {
      this.postId = id
      this.deletePostModal = true
    },
    createUrl(slug) {
      return `${BaseUrl}post/${slug}`
    },
    async getReasonBlockUser() {
      this.isLoading = true
      this.reason = await getReasons()
        .then(response => {
          return response.data.data
        })
        .then(data =>
          data.map(reason => {
            return reason
          })
        )
        .catch(this.$notificationHandleResponseError)
    },
    refreshPagination() {
      this.getPostReportsRequest()
    },
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY HH:mm")
    },
    async getPostReportsRequest() {
      this.isLoading = true
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.rowsPerPage,
        post_id: this.$route.params.id
      }
      await postReportsRequest(params)
        .then(response => {
          this.total = response.data.meta.total
          this.reportList = response.data.data
          this.userInfo = response.data.additional.meta.user
        })
        .catch(this.$notificationHandleResponseError)
        .finally(() => (this.isLoading = false))
    },
    returnBack() {
      if (this.prevRoute && this.prevRoute.fullPath === "/") {
        this.$router.push({ name: "reported_posts" })
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>
<style scoped>
.link-hover:hover {
  color: #fff !important;
  text-decoration: none !important;
}
.date {
  min-width: 120px;
}
</style>
